#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #bc955b;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: "transparent";

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.my-swiper-container {
  background-color: #bc955b; /* Color de fondo deseado */
}

/* Estilo para el contenedor de paginación */
.swiper-pagination {
  bottom: 10px; /* Ajusta la posición si es necesario */
}

/* Estilo para los puntos de paginación */
.swiper-pagination-bullet {
  background-color: #cccccc; /* Color de los puntos inactivos */
  opacity: 1; /* Asegúrate de que los puntos sean completamente visibles */
}

/* Estilo para el punto de paginación activo */
.swiper-pagination-bullet-active {
  background-color: #fff; /* Color del punto activo */
}
